<template>
  <div class="page1">
    <EForm
        :formColumns="formColumns"
        :rowSize="4"
        :optionsBtn="true"
        :actionBtn="false"
        :formData="searchForm"
        ref="form"
        @onSearch="getData"
        :searchFlag="true"
        :exportShow="false"
    >
    </EForm>

    <div class="bgf4f4f5 pd20" v-if="list.length>0">
      <el-radio-group v-model="radio" @change="getChange">
        <div :class="{'mb20':index!=list.length-1}" v-for="(item,index) in list" :key="index">
          <el-radio :label="item.contractId">
            <span class="pr20">摊位号：{{item.boothCode}}</span>
            <span class="pr20">摊位面积：{{item.areas}}</span>
            <span class="pr20">负责人：{{item.signPerson}}</span>
            <span class="pr20">身份证号：{{item.idCard}}</span>
            <span class="pr20">联系电话：{{item.boothCode}}</span>
            <span class="pr20">区域：{{item.areaName}}</span>
            <span>合同状态：{{item.contractStatusCn}}</span>
          </el-radio>
        </div>
      </el-radio-group>
    </div>

    <ETable :tableTitle="tableTitle" :tableData="tableData" :needPagination="false" :count="count"
            @changePage="changePage" :page="searchForm.current" :page_size="searchForm.size">
      <el-table-column fixed="right" label="操作" width="180px">
        <template slot-scope="scope">
          <div>
            <EButton type="text" @click="historyShowDialog(scope.row.orderId,scope.$index)">
              退款记录
            </EButton>
            <EButton type="text" @click="showDialog(scope.row,scope.$index)">
              明细
            </EButton>
          </div>
        </template>
      </el-table-column>
    </ETable>

    <!--订单明细-->
    <el-dialog
        title="订单明细"
        :visible.sync="refunddialogVisible"
        width="75%"
        :before-close="cancelDialog">
      <ETable :tableTitle="refundTableTitle" :tableData="refundlist" :needPagination="false"
              @handleSelect="handleSelect"
              :needSelect="true"
              ref="refundTable">
        <div slot="returnAmount" slot-scope="scope">
          <el-input v-model="scope.data.returnAmount" @blur="()=>{
            if(scope.data.returnAmount>0||Math.abs(scope.data.returnAmount)>Math.abs(scope.data.payAmount)||(scope.data.returnAmount!=0&&scope.data.returnAmount.substring(0,1)==0)){
              $message.error('输入负数，并且小于等于收款金额')
              scope.data.returnAmount=0
              return false
            }
            getfeeNum()
          }"></el-input>
        </div>
      </ETable>

      <!-- 合计-->
      <div class="tr clred">
        <p class="fs15">
          交费：共{{obj.feeNum}}笔，合计：{{obj.totalAmount}}元
        </p>
      </div>
      <!--    是否全部退款-->
      <EForm :formColumns="formColumns3" :rowSize="2" :optionsBtn="false" :actionBtn="true" :formData="form"
            ref="otherForm" :searchFlag="false" labelPosition="left" labelWidth="150px">
      </EForm>
      <!--    是否需要财务确认-->
      <EForm :formColumns="formColumns2" :rowSize="2" :optionsBtn="false" :actionBtn="true" :formData="form"
             ref="otherForm" :searchFlag="false" labelPosition="left" labelWidth="150px">
      </EForm>

      <!--    确认退费按钮-->
      <div style="text-align: center">
        <el-button type="primary" @click="saveData" size="small" :disabled="saveDisabled">提交</el-button>
        <el-button type="primary" @click="cancelDialog" size="small">取消</el-button>
      </div>
    </el-dialog>

    <!--退费记录-->
    <el-dialog
        title="退费记录"
        :visible.sync="historyDialogVisible"
        width="75%"
        :before-close="historyCancelDialog">
      <ETable :tableTitle="historyTableTitle" :tableData="historyrefundlist" :needPagination="false"
              :needSelect="false"
              ref="historyTable">
      </ETable>
    </el-dialog>
  </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import vxRule from "@/assets/js/formValidate";
  import * as math from 'mathjs'
  import {otherMixin} from "@/components/mixin";
  import {item} from "../../assets/js/config";

  export default {
    name: 'refundList',
    mixins: [otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '交费单号',
            prop: 'orderNum'
          },
          {
            label: '区域',
            prop: 'areaName'
          },
          {
            label: '位置',
            prop: 'placeName',
          },
          {
            label: '摊位号',
            prop: 'boothCode'
          },
          {
            label: '商户名称',
            prop: 'tenantName'
          },
          {
            label: '总金额',
            prop: 'totalAmount'
          },
          {
            label: '退费方式',
            prop: 'refundStatusCN'

          },
          {
            label: '最后一次修改时间',
            prop: 'updateTime'
          },
          {
            label: '备注',
            prop: 'remark'
          },
        ],
        formColumns: [
          {
            title: '摊位号',
            type: 'text',
            property: 'code',
            show: true
          },
        ],
        tableData: [],
        searchForm: {
          code: null,
        },
        form: {
          isConfirm: '1',
          refundStatus: '0',
          relationOrderId: 0,
        },
        count: null,
        labelPosition: 'right',
        labelWidth: '100px',
        formRules: {
          boothCode: vxRule(true, '', "blur", "摊位编号不能为空"),
        },
        refundTableTitle: [
          {
            label: '收费项目名称',
            prop: 'takeReturnProjectName'
          },
          {
            label: '年',
            prop: 'year'
          },
          {
            label: '月',
            prop: 'month'
          },
          {
            label: '费用起始日期',
            prop: 'feeBeginDate',
          },
          {
            label: '费用截止日期',
            prop: 'feeEndDate'
          },
          {
            label: '金额',
            prop: 'payAmount'
          },
          {
            label: '第X期',
            prop: 'periods'
          },
          {
            label: '是否到账',
            prop: 'isPayCn'
          },
          {
            label: '是否打印交费单',
            prop: 'isPrintCn'
          },
          {
            label: '退费金额',
            prop: 'returnAmount',
            type: 'slot'
          },
          {
            label: '备注',
            prop: 'remark'
          },
        ],
        historyTableTitle: [
          {
            label: '交费单号',
            prop: 'orderNum'
          },
          {
            label: '区域',
            prop: 'areaName'
          },
          {
            label: '位置',
            prop: 'placeName',
          },
          {
            label: '摊位号',
            prop: 'boothCode'
          },
          {
            label: '商户名称',
            prop: 'tenantName'
          },
          {
            label: '退款金额',
            prop: 'totalAmount'
          },
          {
            label: '财务确认状态',
            prop: 'confirmStatusCn'

          },
          {
            label: '退款时间',
            prop: 'createTime'
          },
        ],
        refunddialogVisible: false,
        historyDialogVisible: false,
        radio: null,
        list: [],
        refundlist: [],
        historyrefundlist: [],
        tempSelectData: [],//临时保存勾选的数据
        index: '',//退费table的每一行index,
        obj: {
          contractId: 0,
          feeNum: 0,
          isConfirm: '1',
          refundItems: [],
          refundOrderIds: [],
          totalAmount: 0,
          refundStatus: '0',
          relationOrderId: 0,
        },
        formColumns2: [
          {
            title: '是否需要财务确认',
            property: 'isConfirm',
            type: 'radio',
            placeholder: '',
            options: [
              {
                label: '是',
                value: '1'
              },
              {
                label: '否',
                value: '2'
              },
            ],
            show: true
          },
        ],
        formColumns3: [
          {
            title: '退费方式',
            property: 'refundStatus',
            type: 'radio',
            placeholder: '',
            options: [
              {
                label: '部分退费',
                value: '1'
              },
              {
                label: '全部退费',
                value: '2'
              },
            ],
            show: true
          },
        ],
      }
    },
    watch: {},
    created() {
    },
    components: {ETable, EDialog, EButton, EForm},
    beforeMount() {

    },
    methods: {
      onSearch() {
        this.searchForm.current = 1
        this.getData()
      },
      changePage(current) {
        console.log(current)
        this.searchForm.current = current
        this.getData()
      },
      cancelDialog() {
        //关闭弹窗时，重新计算amount
        this.obj.feeNum = 0
        this.obj.totalAmount = 0
        this.obj.refundItems = []
        this.refunddialogVisible = false
      },
      historyCancelDialog(){
        this.historyDialogVisible = false;
      },
      async showDialog(tempObj, index) {
        let orderId = tempObj.orderId
        let orderType = tempObj.orderType
        this.form.relationOrderId = orderId
        if(orderType==2){
          this.$message.error('退款订单，不能查看明细')
          return
        }
        this.dialogVisible = true
        this.obj.refundOrderIds = []
        this.obj.refundOrderIds.push({
          id: orderId
        })

        let res = await Http.orderItemsBySecond({id: orderId})
        if (res.code == 200) {
          this.refundlist = res.data.items
        }
        this.refunddialogVisible = true
      },
      async historyShowDialog(orderId, index) {
        this.historyDialogVisible = true
        let res = await Http.refundOrdersBySecond({id: orderId})
        if (res.code == 200) {
          this.historyrefundlist = res.data
        }
        this.form.refundStatus = 0
        this.historyDialogVisible = true
      },
      async getData() {
        if(!this.searchForm.code){
          return
        }
        let res = await Http.contractlist(this.searchForm)
        if (res.code == 200) {
          this.list = res.data
        }
      },
      async getChange() {
        let res = await Http.orderList({
          id: this.radio,
          status:'',
        })
        if (res.code == 200) {
          this.obj.contractId = this.radio
          this.tableData = res.data
        }
      },
      handleSelect(data) {
        this.tempSelectData = data
        this.getfeeNum()
      },
      // 算合计
      getfeeNum(data,index) {
        // 金额
        this.obj.feeNum = 0
        this.obj.totalAmount = 0
        this.obj.refundItems = []
        this.tempSelectData.forEach((item) => {
          // 将退款明细id和金额存入refundItems
          this.obj.refundItems.push({
            itemId: item.orderItemId,
            returnAmount: item.returnAmount
          })
          this.obj.totalAmount += parseFloat(item.returnAmount)
          this.obj.feeNum++
        })
        //取两位小数
        this.obj.totalAmount = math.round(this.obj.totalAmount, 2)
        console.log(this.obj.totalAmount)
      },
      saveData() {
        if (!this.obj.contractId) {
          this.$message.error('请先选择合同相关信息')
          return false
        }
        if(this.form.refundStatus=='0'){
          this.$message.success("请选择退费方式");
          return;
        }
        //交费：共{{obj.feeNum}}笔，合计：{{obj.totalAmount}}元
        if(this.obj.feeNum==0||this.obj.totalAmount==0){
          this.$message.success("请选择退费明细并且退费金额不能为0");
          return;
        }
        this.saveReturnOrder()
        this.refunddialogVisible = false
      },
      //退费
      async saveReturnOrder() {
        this.setDisabled(true)
        this.obj.isConfirm = this.form.isConfirm
        this.obj.refundStatus = this.form.refundStatus
        this.obj.relationOrderId = this.form.relationOrderId
        let res = await Http.saveReturnOrder(this.obj)
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.setDisabled(false)
          this.getChange()
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .ws {
    width: 100%;
  }
</style>
